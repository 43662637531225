export const customTab = {
  inserted(el, binding, vnode) {
    const select = el.querySelector('.el-select');
    const input = el.querySelector('.el-select__input');
    if (input) {
      input.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          const component = vnode.componentInstance;
          const options = component.options;
          if (options.length === 0) {
            component.visible = true;
          } else {
            const firstOption = options[0];
            component.$emit('input', firstOption.value);
            component.selected = firstOption;
            component.visible = false;
          }
        }
      });
    }
  }
};
