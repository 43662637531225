
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import enLang from 'element-ui/lib/locale/lang/en';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css';
import { VueCropper } from 'vue-cropper';
import { customTab } from '@/utils/assembly.js';

Vue.use(VueAwesomeSwiper)
// ElementUI.Dialog.props.closeOnClickModal.default = false;

Vue.config.productionTip = false
Vue.component('vue-cropper', VueCropper)

Vue.directive('custom-tab', customTab)


Vue.use(ElementUI,{
  locale: enLang
});


new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')

