/*
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-04-02 13:44:47
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-04-03 13:40:00
 * @FilePath: \medical_diet_font_new\src\router\index.js
 * @Description: This is the default setting. Please set 'customMade' and open koroFileHeader to view the configuration for setting: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/UserInfo.vue')
  },
  {
    path: '/newRegister',
    name: 'newRegister',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/newRegister.vue')
  },
  {
    path: '/newSubmitReferral',
    name: 'newSubmitReferral',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/NewSubmitReferral/NewSubmitReferral.vue')
  },
  {
    path: '/setInfo',
    name: 'setInfo',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/SetInfo.vue')
  },
  {
    path: '/chooseRole',
    name: 'chooseRole',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ChooseRole.vue')
  },
  {
    path: '/forget',
    name: 'forget',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/forget/index.vue')
  },
  {
    path: '/ordinaryUsers',
    name: 'ordinaryUsers',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/ordinaryUsers.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgetPassword.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      // let item = localStorage.getItem("userInfo");
      const expiration = parseInt(localStorage.getItem('expiration'));
      if (expiration && expiration > new Date().getTime()) {
        next();
      } else {
        alert("The current login status has expired. Please log in again");
        next(false);
        window.location.reload()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
