<template>
  <div class="loginBox">
    <div class="topBox">Performance Kitchen Professional Portal</div>
      <!--    <Swiperse></Swiperse>-->
    <div class="middleLoginBox">
<!--      <div class="middleLoginBoxLeft">-->
<!--        <img src="@/assets/images/bottom_logo@2x.png" alt="" class="middimg"/>-->
<!--      </div>-->
      <div class="middleLoginBoxRight">
        <div class="rowColStyle">
          <div class="rowTitleBox">
            <div class="rowTitle">Sign in</div>
          </div>
          <div class="rowText">
            <div>
              <span class="rowLeftText">Do not have an account ? </span>
              <span class="rowRightText" @click="goToRegister">Register Now</span>
            </div>
          </div>
          <div>
            <div class="word">
              <div style="font-size: 1rem">USERNAME/EMAIL</div>
            </div>
          </div>
          <div>
            <div>
              <el-input v-model="email" class="elInput"/>
            </div>
          </div>
          <div>
            <div class="word">
              <span style="font-size: 1rem">PASSWORD</span>
            </div>
          </div>
          <div>
            <div>
              <el-input v-model="password" type="password" class="passwords"/>
            </div>
          </div>
          <div style="font-size: 1rem;color: #1F5014;cursor: pointer;" @click="clickForGet">
            Password Recovery
          </div>
          <div style="color: red;margin-top: 16px" v-if="!errorLogin">
            The username or password you entered does not match our records
          </div>
          <div>
            <el-button type="success" class="elbut" style="width: 100%;height: 50px" @click="toHome">Sign in</el-button>
          </div>
        </div>
      </div>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
import Api from "@/api/api";
import Footers from "./foot.vue";
import Swiperse from "@/views/swiper.vue";
import { getClientIP } from '@/utils/ip';

export default {
  name: "Login",
  components: {
    Footers,
    Swiperse,
  },
  data() {
    return {
      dialogVisible: false,
      sendEmails: "",
      email: "",
      password: "",
      errorLogin:true,
      clientIP:"",
    }
  },
  methods: {
    async getIp(){
      this.clientIP = await getClientIP();
      sessionStorage.setItem("clientIP", this.clientIP);
    },
    clickForGet() {
      this.$router.push("/forget");
    },
    toHome() {
      if (!this.email) {
        this.$message.error("userName not null");
        return;
      }
      // let regular = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      // if(!regular.test(this.email)){
      //   this.$message.error("Email does not comply with rules");
      //   return;
      // }
      if (!this.password) {
        this.$message.error("password not null");
        return;
      }

      let obj = {};
      obj.userName = this.email;
      obj.password = this.password;
      var fullUrl = window.location.href;
      obj.fullUrl = fullUrl;
      obj.ipAddr = this.clientIP;
      Api.loginProduct(obj).then(res => {
        if (res.data.data.response == "SUCCESS") {
          // const expiration = new Date().getTime() + 60 * 60 * 1000 * 24 * 7;
          //   localStorage.setItem('expiration', expiration.toString());
          //   this.$router.push('/home');
          // localStorage.setItem("userInfo",JSON.stringify(res.data.userInfo));
          // if (res.data.userInfo.proKey){
          this.getProViewByProId(res.data.data.proId)
          // }else{
          //   const expiration = new Date().getTime() + 60 * 60 * 1000 * 24 * 7;
          //   localStorage.setItem('expiration', expiration.toString());
          //   this.$router.push('/home');
          // }
        } else {
          this.errorLogin=false;
          // this.$message.error("The username or password you entered does not match our records");
        }
      })
    },
    goToRegister() {
      this.$router.push('/register');
    },
    toForget() {
      this.$router.push('/forgetPassword');
    },
    openForGetUserName() {
      this.dialogVisible = true
    },
    getSpProView(e) {

      Api.getSpProView(e).then(res => {
        if (res.data.code == 200) {
          if (res.data.data !== "" && res.data.data !== null) {
            this.pFullName = res.data.data.pfullName;
            console.log(res.data.data)
            let data = res.data.data;
            sessionStorage.setItem("ProView", JSON.stringify(data));
            if (data.puserName == null) {
              this.$router.push("/register")
            }
          }

        } else {
          this.$message.error("unknown error");
        }
      })
    },
    getProViewByProId(e) {
      Api.getProViewByProId(e).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          sessionStorage.setItem("ProView", JSON.stringify(data));
          sessionStorage.setItem("proId", data.pkey);
          const expiration = new Date().getTime() + 60 * 60 * 1000 * 24 * 7;
          localStorage.setItem('expiration', expiration.toString());

          this.$router.push('/home');
        } else {
          this.$message.error("unknown error");
        }
      });
    },
  },
  mounted() {
    let org = this.$route.query.org;
    let proId = this.$route.query.key;
    const href = window.location.href;
    sessionStorage.setItem("href",href);
    // var proKey=location.pathname.split("/")[2];
    // console.log("----------------------------",proKey);
    if (org !== undefined && org !== null) {
      sessionStorage.setItem("org", org);
    }
    if (proId !== undefined && proId !== null) {
      sessionStorage.setItem("proId", proId);
      this.getSpProView(proId);
    }
  },
  created() {
    this.getIp();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-button--success {
  margin-top: 30px;
  color: #1F5014;
  //background-color: #67C23A;
  border-color: #1F5014;
  background: #FFFFFF;
  font-size: 14px;
  text-align: center;
  width: 100%;
  justify-content: center;
  //margin-left: 50px;
  font-weight: bold;
}

::v-deep .el-button--success:hover {
  margin-top: 30px;
  color: #FFF;
  //background-color: #67C23A;
  border-color: #1f5014;
  background: #1f5014;
  font-size: 14px;
  text-align: center;
  width: 100%;
  justify-content: center;
  //margin-left: 50px;
  font-weight: bold;
}

::v-deep .el-button--success:disabled {
  margin-top: 30px;
  color: #bcbec2;
  background-color: #f4f4f5;
  border-color: #e9e9eb;
  font-size: 14px;
  text-align: center;
  width: 100%;
  justify-content: center;
  //margin-left: 50px;
  font-weight: bold;
}

::v-deep.el-input-group__prepend {
  border-left: 1px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}


.middimg {
  width: 100%;
  display: block;
  cursor: pointer;
}

.topBox {
  text-align: center;
  line-height: 50px;
  color: #fff;
  width: 100%;
  height: 50px;
  background-color: #648140;
  position: fixed;
  top: 0;
}

.bottomBoxLeft {
  width: 40%;
}

.bottomBoxRight {

  text-align: right;
  color: white;

}

.elInput {
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;
}

.passwords {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.middimgs {
  width: 50%;
  min-width: 50%;
}

.bottomBox {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #648140;
  z-index: 999;
}

.bottomWrap {
  height: 100px;
  display: flex;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  justify-content: space-between;
}

.rowText {
  font-size: 1.25rem;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
}

.rowTitle {
  font-size: 1.5rem;
  font-weight: 700;
}

.rowLeftText {
  font-size: 1rem;
  color: #999;
}

.rowRightText {
  color: #789d4a;
  text-decoration: underline;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem !important;
}

.loginBox {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;


  //position: relative;
}

.middleLoginBox {
  margin: 0 auto;
  width: 60%;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
}

.middleLoginBoxLeft {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middleLoginBoxRight {
  width: 40%;
  border-radius: 10px;
  min-width: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 20px;
}



.word {
  display: flex;
  font-size: 1rem;
}

.Pass {
  margin-left: 10px;
  font-size: 1rem;
  color: #999999;
  cursor: pointer;
}

.rowColStyle {
  min-height: 10%;
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 10% 0;

}

@media screen and (max-width: 1408px) {

}

@media screen and (max-width: 1260px) {
  .middleLoginBox {
    width: 50%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }
  .middleLoginBoxRight {
    width: 100%;
  }
  .rowLeftText {
    font-size: 16px;
  }
  .rowRightText {
    font-size: 16px;
  }
  .word {
    width: 100% !important;
  }
  .rowText {
    margin-bottom: 0.3rem;
  }
  .loginBox {
    min-height: 100vh;
  }
  .word {
    width: 14px;
  }

}


@media screen and (max-width: 770px) {
  .topBox{
    position: fixed;
    top: 0;
  }
  .rowLeftText {
    font-size: 16px;
  }
  .loginBox {
    overflow-y: auto;
    max-height: 70rem;

  }
  .rowText {
    margin-top: 20px;
    font-size: 0.4rem;
  }
  .rowTitle {
    font-size: 1.4rem;
    font-weight: 700;
  }
  .word {
    font-size: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Pass {
    display: block;
    margin: 0;
    font-size: 14px;
  }
  .rowColStyle {
    width: 60%;
    height: 50%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 10% 0;
  }

  .elbut {
    margin-top: 1rem;
    font-size: 1rem !important;
    text-align: center;
  }
  .elInput {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
  .passwords {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  .middleLoginBoxRight {
    height: 70%;
    border-radius: 10px;
    min-width: 75%;
    background-color: white;
  }
  .bottomBoxRight {
    line-height: 1.25rem;
  }
  .bottomBoxRight {

    color: white;
    font-size: 0.3rem;
    word-wrap: break-word;

  }
  .middleLoginBoxLeft {
    width: 50%;
    margin-bottom: 3.2rem;
    height: auto;
  }
  .middleLoginBox {
    width: 80%;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    // display: flex;
    // flex-direction: column;
    height: auto;
  }
  .bottomWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .bottomBox {
    // z-index: 2;
    position: fixed;
    bottom: 0;
  }
  .bottomBoxLeft {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .rowColStyle {
    padding-top: 20px;
  }
  .Pass {
    font-size: 14px;
  }
  .middleLoginBox {
    width: 80%;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    // display: flex;
    // flex-direction: column;
    height: auto;
  }
  .middleLoginBoxRight {
    min-width: 100%;
  }
  .middleLoginBoxLeft {
    margin-bottom: 2rem;
    width: 76%;
  }
  .middimg {
    width: 100%;
  }
  .rowColStyle {
    width: 85%;
  }

}
</style>
