
<template>
 <div>
  <router-view />
 </div>
</template>

<script >


export default {
    name: "App",

};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
html,body,#app{
  -webkit-overflow-scrolling: touch;
}
</style>
<style>
@font-face {
  font-family: 'HVD Fonts';
  src: url('@/assets/css/static/hvd-fonts-brandontext-regular.otf') format('opentype');
}

* {
  font-family: 'HVD Fonts', sans-serif;
  font-size: 16px !important;
}
</style>
<style>


.el-message-box__wrapper .el-button--primary{
  background: #648140 !important;
      color: #fff;
      border-color:#648140 !important
}
.el-message-box__wrapper >>> .el-button:focus {
  border-color:#648140 !important
}
.el-message-box__wrapper >>> .el-button--primary:focus{
  border-color:#648140 !important
}
.el-message-box__wrapper >>> .el-button--primary:hover{
  border-color:#648140 !important
}

.el-select .el-input.is-focus .el-input__inner {
    border-color: #648140 !important;
}
.el-pager li.active {
    color: #648140 !important;
}

</style>

