
<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      // currentImage: require("../assets/images/bn1.jpg"),
      // currentIndex: 0,
      swiperOption: {
      loop: true,
      effect: "fade",//
      parallax: true,//
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        clickable: true
      },
    },
      images: [
        {
          img: require("../assets/images/bn1.jpg"),
        },
        {
          img: require("../assets/images/bn2.jpg"),
        },
        {
          img: require("../assets/images/bn3.jpg"),
        },
        {
          img: require("../assets/images/bn4.jpg"),
        },
        {
          img: require("../assets/images/bn5.jpg"),
        },
      ],

    }
  },
  mounted() {
    // this.startCarousel();
  },
  methods: {
    
    //   startCarousel() {
    //     setInterval(() => {
    //       console.log(this.currentIndex)
    //       this.currentIndex = (this.currentIndex + 1) % this.images.length;
    //       this.currentImage = this.images[this.currentIndex]["img"];
    //       console.log(this.currentImage)
    //     }, 6000);
    //   }
  }
}
</script>

<template>
  <div>

    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item, i) in images" :key='i'><img data-swiper-parallax="0" data-swiper-parallax-opacity="0.3"
          data-swiper-parallax-duration="3000" :src="item.img" alt=""></swiper-slide>
      <!-- <swiper-slide><img  data-swiper-parallax="0" data-swiper-parallax-opacity="0.5" data-swiper-parallax-x data-swiper-parallax-duration="5000" src="https://img0.baidu.com/it/u=413092472,4125603860&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800" alt=""></swiper-slide>
			<swiper-slide><img  data-swiper-parallax="0" data-swiper-parallax-opacity="0.5"  data-swiper-parallax-duration="3000" src="https://img2.baidu.com/it/u=2598981474,2305186611&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500" alt=""></swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
  </div>
</template>

<style lang="scss" scoped>
.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.swiper-slide,.swiper-wrapper img{
  width: 100%;
height: 100vh;

}









</style>
