// import axios
import axios from 'axios'
//  use element-ui Message Make a message alert
import { Message } from 'element-ui';
//1. Create a new axios instance,
const service = axios.create({
  // Common interface
  baseURL: process.env.BASE_API,
  // The timeout unit is ms. The timeout period is set for 3s
  timeout: 1000 * 1000
})
// 2.Request interceptor
service.interceptors.request.use(config => {
  //Some processing before sending the request, such as data conversion, configuring the request header, setting the token, setting loading, etc., and adding according to the demand
  if (config.data instanceof FormData) {
    // If it is FormData, do not set Content Type, let the browser automatically set it
    delete config.headers['Content-Type'];
  } else {
    // For non FormData requests, handle them in the same way as before
    config.data = JSON.stringify(config.data);
    config.headers['Content-Type'] = 'application/json';
  }
  //If necessary: Note that when using token, it is necessary to introduce cookie method or use local localStorage method, js-cookie is recommended
  //const token = getCookie(' name '); // Before you get the token here, you definitely need to get the token first and deposit it
  //if(token){
  //config.params = {'token':token} //If required to carry in the parameter
  //config.headers.token= token; //If the request is carried in the request header
  //}
  return config
}, error => {
  Promise.reject(error)
})

// jie kai zhu shi
// 3.Response interceptor
// service.interceptors.response.use(response => {
//   //Some common processing after receiving the response data and success, turn off loading, etc
//
//   return response
// }, error => {
//   /***** Processing of the received exception response begins *****/
//   if (error && error.response) {
//     // 1.Common error handling
//     // 2.Handle according to the response code
//     switch (error.response.status) {
//       case 400:
//         error.message = 'Error request'
//         break;
//       case 401:
//         error.message = 'No authorization, please log in again'
//         break;
//       case 403:
//         error.message = 'access denied'
//         break;
//       case 404:
//         error.message = 'Request error. The resource was not found'
//         window.location.href = "/NotFound"
//         break;
//       case 405:
//         error.message = 'Request method not allowed'
//         break;
//       case 408:
//         error.message = 'request timeout'
//         break;
//       case 500:
//         error.message = 'Server side error'
//         break;
//       case 501:
//         error.message = 'Network not implemented'
//         break;
//       case 502:
//         error.message = 'network error'
//         break;
//       case 503:
//         error.message = 'Service unavailable'
//         break;
//       case 504:
//         error.message = 'network timeout'
//         break;
//       case 505:
//         error.message = 'The http version does not support this request'
//         break;
//       default:
//         error.message = `connection error${error.response.status}`
//     }
//   } else {
//     // Timeout processing
//     if (JSON.stringify(error).includes('timeout')) {
//       Message.error('The server response times out. Please refresh the current page')
//     }
//     error.message = 'Failed to connect to server'
//   }
//
//   Message.error(error.message)
//   /***** End of processing *****/
//   //If error handling is not required, the above procedures can be omitted
//   return Promise.resolve(error.response)
// })
//4.import a file
export default service
