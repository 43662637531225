import request from './request'

const http = {
  /**
   * methods: request
   * @param url
   * @param params
   */
  get(url, params) {
    const config = {
      method: 'get',
      url: url
    }
    if (params) config.params = params
    return request(config)
  },
  post(url, params) {
    const config = {
      method: 'post',
      url: url
    }
    if (params) config.data = params
    return request(config)
  },
  post2(url, params, config = {}) {
    const defaultConfig = {
      method: 'post',
      url: url
    }

    // Merge default configuration and incoming configuration
    const mergedConfig = { ...defaultConfig, ...config }

    if (params) {
      if (params instanceof FormData) {
        // If it is FormData, do not set Content Type and let the browser automatically set it
        mergedConfig.data = params
        mergedConfig.headers = {
          ...mergedConfig.headers,
          'Content-Type': 'multipart/form-data'
        }
      } else {
        // If it's not FormData, handle it in the original way
        mergedConfig.data = params
      }
    }

    return request(mergedConfig)
  },

  put(url, params) {
    const config = {
      method: 'put',
      url: url
    }
    if (params) config.params = params
    return request(config)
  },
  delete(url, params) {
    const config = {
      method: 'delete',
      url: url
    }
    if (params) config.params = params
    return request(config)
  }
}
//export
export default http
